<template>
  <div id="userfully">
      <div class="fully-nav">
          <img class="fully-img" src="https://cdn.bjtdba.com/vod/image/2023-06-08/20230608104700153.png" alt="">
          <div class="fully-txt">恭喜您<br />已经成为储蓄保用户<br />请登录储蓄保app查看您的收益。</div>
          <div class="fully-btn" @click="gotoCenter()">打开储蓄保APP</div>
      </div>
      
  </div>
</template>

<script>
export default {
  name: 'OpenPension',
  props: {
    msg: String
  },
  data() {
    return {

    }
  },
  created(){
      //不是微信并且不是支付宝
      if (!this.$isWechat() && !this.$isAlipay()) {
          alert('请使用微信或支付宝扫码');
      }
  },
  methods: {
      gotoCenter(){
          window.location.href = "https://app.bjtdba.com/share/download.html";
      }
  }
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
body {
  background: #F6F6F6;
}
* {
  margin: 0;
  padding: 0;
}
#userfully {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F6F6F6;
  text-align: center;
}
.fully-nav{
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.fully-img{
  width: 4.5rem;
  height: 4.5rem;
}
.fully-txt{
  margin-top: 20px;
  font-size: 18px;
}
.fully-btn{
    text-align: center;
    background: #FE0032;
    color: #ffffff;
    border-radius: 5px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
  padding: 7px;
  margin: 30px auto 0;
}
</style>
