<template>
	<div id="userfully">
		<img class="back_img" src="../assets/支付成功.png" alt="">
		<div class="fk_head">
			<div>
				<img class="zf_icon" src="../assets/形状结合.png" alt="">
			</div>
			<div>支付成功</div>
		</div>
		<div class="fk_content">
			<div class="content_t">
				<div class="content_title">本次消费获得养老金</div>
				<div class="content_price"><span
						style="width: 0.1rem;height: 0.1rem; font-size: 0.4rem;">￥</span>{{ money }}
				</div>
			</div>
			<div class="content_text">
				<div class="flex_o">
					<div>总养老金</div>
					<div>******</div>
				</div>
				<div class="flex_o">
					<div>中信公证处</div>
					<div>******</div>
				</div>
			</div>
		</div>
		<div class="fully-btn" @click="clickAction">查看我的养老金</div>
		<!-- <div class="fully-btn" @click="clickAction" v-if="isWeachat !== true">查看我的养老金</div>
		<div class="fully-btn1" v-if="isWeachat">
			<wx-open-launch-weapp id="launch-btn" username="gh_d9e58585791b" path="pages/home/index">
				<button style="border: none; background-color: #FE0032; color: #ffffff;">去小程序查看我的养老金</button>
			</wx-open-launch-weapp>
		</div> -->

		<div class="yqhy_img">
			<img style="width: 100%;height: 100%;" src="../assets/邀请好友.png" alt="">
		</div>

		<div v-if="centerDialogVisible" class="dialog_div">
			<img class="ewm_img" src="../assets/cxb.png" alt="">
			<span class="dialog-footer" @click="centerDialogVisible = false">
				<img src="../assets/关闭.png" style=" padding-top: 0.3rem;width: 1rem;height: 1rem;" alt="">
			</span>
		</div>



		<!-- <div class="fully-nav" >
            <img class="fully-img" v-show="tu" src="https://cdn.bjtdba.com/vod/image/2023-06-08/20230608104700153.png" alt="">
            <div class="fully-txt">支付成功</div>
            <div class="fully-txt">本次消费获得养老金 <span v-html="money"></span></div>
            <div class="fully-btn" @click="tankuan()">查看我的养老金</div>
            <div class="fully-btn" @click="gotoCenter()">打开储蓄保APP</div>
        </div> -->

	</div>
</template>

<script>
	// import wx from 'weixin-js-sdk';
	import {
		queryOrders
	} from '../api/index'
	export default {
		name: 'OpenPension',
		props: {
			msg: String
		},
		data() {
			return {
				tu: false,
				money: '0',
				centerDialogVisible: false,
				isWeachat: false
			}
		},
		created() {
			let out_trade_no = localStorage.getItem('out_trade_no');
			console.log(out_trade_no)
			queryOrders({
				out_trade_no: out_trade_no
			}).then(resPay => {
				if (resPay.data.pension != undefined && resPay.data.pension > 0) {
					this.money = resPay.data.pension
				}
			}).catch(resPay => {
				console.log(resPay)
				// alert(resPay.message)
			})

			if (this.$isWechat()) {
				this.isWeachat = true;
			} else {
				this.isWeachat = false;
			}
		},

		mounted() {
			let mchData = {
				action: 'onIframeReady',
				displayStyle: 'SHOW_CUSTOM_PAGE',
				height: 960
			};
			let postData = JSON.stringify(mchData);
			parent.postMessage(postData, 'https://payapp.weixin.qq.com');




			// var launchBtn = document.getElementById('launch-btn')
			// console.log('btn', launchBtn)
			// launchBtn.addEventListener('ready', function(e) {
			// 	console.log('开放标签 ready', e.detail)
			// })
			// launchBtn.addEventListener('launch', function(e) {
			// 	console.log('开放标签 success', e.detail)
			// })
			// launchBtn.addEventListener('error', function(e) {
			// 	console.log('开放标签 fail', e.detail)
			// })

			// if (this.$isWechat()) {
			// 	var userInfor = JSON.parse(localStorage.getItem('userInfor'));
			// 	console.log('userInfo',userInfor)
			// 	createOrder({
			// 		money: '1',
			// 		mer_id: '461',
			// 		trade_type: "WX",
			// 		avatar: userInfor.headimgurl,
			// 		nickname: userInfor.nickname,
			// 		pay_user_id: userInfor.unionid
			// 	}).then(res => {
			// 		if (res.status == 200) {
			// 			// 支付
			// 			jsapiPay({
			// 				out_trade_no: res.data.out_trade_no,
			// 				type: 'HF'
			// 			}).then(resPay => {
			// 				if (resPay.status == 200) {

			// 					var pay_info = JSON.parse(resPay.data.expend.pay_info)
			// 					wx.config({
			// 						debug: true, // 调试时可开启
			// 						appId: 'wxfb755febdb800f23', // <!-- replace -->
			// 						timestamp: pay_info.timeStamp, // 必填，填任意数字即可
			// 						nonceStr: pay_info.nonceStr, // 必填，填任意非空字符串即可
			// 						signature: pay_info.paySign, // 必填，填任意非空字符串即可
			// 						jsApiList: ['chooseWXPay'], // 必填，随意一个接口即可 
			// 						openTagList: ['wx-open-launch-weapp'], // 填入打开小程序的开放标签名
			// 					})
			// 				}
			// 			}).catch(resPay => {
			// 				alert(resPay.message)
			// 			})
			// 		} else {
			// 			alert(res.message)
			// 		}
			// 	}).catch(resMer => {
			// 		alert(resMer.message)
			// 	})
			// }
			// console.log('初始化完毕')
		},

		methods: {
			tankuan() {
				this.tu = true;
			},
			gotoCenter() {
				window.location.href = "https://app.bjtdba.com/share/download.html";
			},

			clickAction() {
				// if (this.$isWechat()) {
				// 	console.log('微信')
				// } else {
				// 	this.centerDialogVisible = true;
				// }
				this.centerDialogVisible = true;
			}
		}
	}
</script>

<style scoped>
	* {
		margin: 0;
		padding: 0;
	}

	#userfully {
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	.back_img {
		width: 100%;
		height: 50%;
	}

	.dialog_div {
		background-color: transparent;
		z-index: 999;
		position: absolute;
		top: 15%;
	}

	.fk_head {
		position: absolute;
		top: 1.2rem;
		width: 4rem;
		height: 1.5rem;
		display: flex;
		justify-content: space-between;
		align-content: center;
		font-size: 0.5rem;
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 1.5rem;

	}

	.content_t {
		padding-bottom: 0.4rem;
		border-bottom: 1px solid #ccc;
	}

	.zf_icon {
		width: 1.5rem;
		height: 1.5rem;

	}

	.fk_content {
		width: 8rem;
		height: 4rem;
		position: absolute;
		top: 25%;
	}

	.content_text {
		padding-top: 0.2rem;
	}

	.flex_o {
		display: flex;
		justify-content: space-between;
		flex: 1;
		height: 0.6rem;
		font-size: 0.4rem;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		color: #333333;
		line-height: 0.6rem;

	}

	.content_title {
		margin-top: 0.2rem;
		height: 0.5rem;
		font-size: 0.45rem;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		color: #333333;
		line-height: 0.5rem;
	}

	.content_price {
		height: 1rem;
		font-size: 0.65rem;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		color: #333333;
		line-height: 1rem;
	}


	.fully-btn {
		position: absolute;
		top: 52%;
		text-align: center;
		background: #FE0032;
		color: #ffffff;
		border-radius: 5px;
		font-size: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40%;
		padding: 7px;
		margin: 0 auto 30px;
	}

	.fully-btn1 {
		position: absolute;
		top: 52%;
		text-align: center;
		background: #FE0032;
		color: #ffffff;
		border-radius: 5px;
		border: none;
		font-size: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 60%;
		padding: 7px;
		margin: 0 auto 30px;

	}

	.yqhy_img {
		position: absolute;
		top: 61%;
		width: 90%;
		height: 15%;
	}

	.ewm_img {
		width: 100%;
		height: 10rem;
	}
</style>